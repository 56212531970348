<template>
  <figure :class="$style.container">
    <video
      v-if="config.isVideo"
      ref="video"
      autoplay
      muted
      :style="{ backgroundImage: `url(${config.poster})` }"
      @ended="onVideoEnd"
    >
      <source v-if="screenSize === 'small'" :src="config.videoSrcSmallMp4" type="video/mp4" />
      <source v-if="screenSize === 'small'" :src="config.videoSrcSmallWebm" type="video/webm" />
      <source v-if="screenSize === 'medium'" :src="config.videoSrcMediumMp4" type="video/mp4" />
      <source v-if="screenSize === 'medium'" :src="config.videoSrcMediumWebm" type="video/webm" />
      <source v-if="screenSize === 'large'" :src="config.videoSrcLargeMp4" type="video/mp4" />
      <source v-if="screenSize === 'large'" :src="config.videoSrcLargeWebm" type="video/webm" />
    </video>
    <img v-else-if="config.imageSrc" :src="config.imageSrc" :alt="config.imageAlt" />
    <figcaption v-if="randomText">
      <div>
        <span
          v-for="(term, index) in randomText.terms"
          :key="index"
          :class="{ VideoImage__animating: animating }"
        >
          {{ term }}
        </span>
      </div>
      <p v-if="randomText.slogan">
        {{ randomText.slogan }}
      </p>
    </figcaption>
  </figure>
</template>

<script setup>
import Viewports from '../../globals/constants/viewports'
import { onBeforeMount, onMounted, onUnmounted, ref } from 'vue'

const props = defineProps({
  config: {
    type: Object,
    default: () => {}
  }
})

// * screen size

const screenSize = ref(null)

const initVideo = () => {
  screenSize.value = 'large'

  if (window.matchMedia(`(max-width: ${Viewports.SMALL_MAX})`).matches) {
    screenSize.value = 'small'
  } else if (
    window.matchMedia(`(min-width: ${Viewports.SMALL}) and (max-width: ${Viewports.MEDIUM_MAX})`)
      .matches
  ) {
    screenSize.value = 'medium'
  }
}

// * animation

const startAnimation = () => {
  animating.value = true

  startTimeOut.value = setTimeout(() => {
    animating.value = false

    generateRandomText()
  }, totalAnimationDuration.value)
}

const initAnimation = () => {
  restartTimeOut.value = setTimeout(() => startAnimation(), 250)
}

// * video

const video = ref(null)

const swapVideoSrc = () => {
  let screenSizeNew = 'large'

  if (window.matchMedia(`(max-width: ${Viewports.SMALL_MAX})`).matches) {
    screenSizeNew = 'small'
  } else if (
    window.matchMedia(`(min-width: ${Viewports.SMALL}) and (max-width: ${Viewports.MEDIUM_MAX})`)
      .matches
  ) {
    screenSizeNew = 'medium'
  }

  if (screenSize.value !== screenSizeNew) {
    screenSize.value = screenSizeNew

    if (video.value) {
      video.value.load()
    }
  }
}

const onVideoEnd = () => video.value.play()

// * random text

const animating = ref(false)
const animationDelay = 1.5
const randomText = ref(null)
const restartTimeOut = ref(null)
const startTimeOut = ref(null)
const totalAnimationDuration = ref(0)
const termAnimationDuration = 4

const generateRandomText = () => {
  clearTimeout(startTimeOut.value)
  clearTimeout(restartTimeOut.value)

  animating.value = false

  if (!props.config.texts) {
    return
  }

  const randomIndex = Math.floor(Math.random() * props.config.texts.length)

  randomText.value = props.config.texts[randomIndex]
  randomText.value.terms.sort(
    // eslint-disable-next-line no-unused-vars
    (a, b) => 0.5 - Math.random()
  )
  totalAnimationDuration.value =
    ((randomText.value.terms.length - 1) * animationDelay + termAnimationDuration) * 1000

  initAnimation()
}

// * lifecicle

onBeforeMount(() => generateRandomText())

onMounted(() => {
  let transparentHeader = document.querySelector('.transparentHeader')

  if (transparentHeader) {
    transparentHeader.classList.add('MicrositePageHeader__transparent')
  }

  if (props.config.isVideo) {
    initVideo()
    window.addEventListener('resize', swapVideoSrc)
  }
})

onUnmounted(() => {
  if (props.config.isVideo) {
    window.removeEventListener('resize', swapVideoSrc)
  }
})
</script>

<style module lang="scss">
@import './VideoImage.scss';
</style>
