import { createApp } from 'vue'
import App from './App.vue'

export default function init() {
  function mountApp(element) {
    const app = createApp(App, { ...element.dataset })

    app.mount(`#${element.id}`)
  }

  const elements = document.querySelectorAll('.rotatingCarousel')

  if (elements.length) {
    elements.forEach((element) => {
      if (element.closest('div[id^="tab-content-"]')) {
        return
      }

      mountApp(element)
    })
  }

  window.initRotatingCarouselInContentTabs = () => {
    const containers = document.querySelectorAll('.ContentTabs__container')

    containers.forEach((container) => {
      const subApp = container.querySelectorAll('.rotatingCarousel')

      subApp.forEach((map) => {
        const modifiedId = `${map.id}-in-tab`
        map.id = modifiedId
        mountApp(map)
      })
    })
  }
}
