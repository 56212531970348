<template>
  <div>
    <form ref="FilterForm" :class="$style.container">
      <div class="columns">
        <div class="column is-half-tablet is-offset-one-quarter-tablet">
          <div v-if="canShowSearch()" :class="$style.search">
            <h3 v-if="props.config.texts.searchTitle" :class="$style.searchTitle">
              {{ props.config.texts.searchTitle }}
            </h3>
            <Search
              :search-placeholder="props.config.texts.searchPlaceholder"
              @submitted="getSearchResults"
              @reset="searchReset"
              @change="handleSearchInput"
            />
          </div>
        </div>
      </div>
      <p v-if="props.config.texts.filterIntro" :class="$style.filterIntro">
        {{ props.config.texts.filterIntro }}
      </p>
      <div v-if="showFilter" :class="$style.selects">
        <div class="columns is-multiline">
          <div
            v-for="(configFilter, index) in configFiltersWithoutUnsolicitedApplicationAndTalentPool"
            :key="index"
            :class="[
              configFiltersWithoutUnsolicitedApplicationAndTalentPool.length < 5
                ? 'is-one-quarter-desktop'
                : 'is-one-fifth-desktop',
              'column is-half-tablet'
            ]"
          >
            <FormSelect
              v-show="showMultiSelects || showFilterOnNoResult || selectOptions[index]"
              :key="index"
              :ref="configFilter.key"
              v-model="configFilter.initialSelectOption"
              :has-filter="configFilter.hasFilter"
              :value="getInitialFilterValue(configFilter)"
              :label="configFilter.label"
              :colour="configFilter.colour"
              :options="selectOptions[index]"
              :initial-option="
                configFilter.initialSelectOption || (configFilter.isMultiSelect && [])
              "
              :placeholder="configFilter.placeholder || configFilter.initialSelectOption"
              :disabled="!selectOptions[index]"
              :is-multi-select="configFilter.isMultiSelect"
              :active-select="activeSelect"
              :category="configFilter.key"
              :reset-multi-selects="resetMultiSelectsRef"
              @update="updateFilters(configFilter, $event)"
              @external-reset="(resetValue) => (resetMultiSelectsRef = resetValue)"
            />
          </div>
        </div>
        <button
          v-if="
            configFilters.length &&
            configFilters.some((filterButton) => {
              return filterButton.colour === 'button'
            })
          "
          :class="[
            $style.flyOutFilter,
            flyOutFilterActive === true ? $style.flyOutFilterActive : null
          ]"
          @click.prevent="handleFilterButtonChange"
          v-html="config.texts.filterButtonText"
        />
      </div>
      <div v-if="showHelpText" class="SubPage__mobileSpaced">
        {{ config.texts.helpText }}
      </div>
      <div v-if="showResultText" class="SubPage__mobileSpaced">
        {{ config.texts.resultText }}<span>{{ filteredItems?.length }}</span>
        <template v-if="showReset">
          <button :class="$style.filterReset" @click.prevent="resetMultiSelectsRef = true">
            {{ config.texts.resetText }}
            <span :class="$style.filterResetIcon">×</span>
          </button>
        </template>
      </div>
      <div v-else-if="loadData" class="preloadFilter">
        <Loading />
      </div>
    </form>
    <div v-if="config.withTeasers && !hasResults" :class="[$style.teasers, 'columns is-multiline']">
      <div
        v-for="(teaser, index) in data.teasers"
        :key="index"
        class="column is-half-tablet is-one-third-desktop"
      >
        <ContentOverviewTeaser
          :image="teaser.image"
          :title="teaser.title"
          @clicked="updateFromTeaser(teaser.title)"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import axios from 'axios'
import { computed, onBeforeMount, onBeforeUnmount, onMounted, onUnmounted, ref, watch } from 'vue'
import { DownloadCategory } from '../../globals/constants/download'
import { FormFilterDataTypeEnum, ListCategory } from '../../globals/constants/formFilter'
import * as Storage from '../../shared/Storage'
import { geoIPUrl } from '../../shared/globals'
import { convertISODateTimeToLocaleDate } from '../../utils/dateTime'
import { getValueByParameterCaseInsensitive } from '../../utils/helper'
import { sortInGivenArray } from '../../utils/sort'
import getSearchResultFromUrl from '../../xhr/search/getSearchResultFromUrl'
import ContentOverviewTeaser from '../ContentOverviewTeaser/ContentOverviewTeaser.vue'
import { CategoryKeys } from '../DownloadList/constants/categoryKeys'
import FormSelect from '../FormSelect/FormSelect.vue'
import Loading from '../Loading/Loading.vue'
import Search from '../Search/Search.vue'

const emit = defineEmits(['filtered', 'has-results', 'loadingChange', 'resetFilters'])

const props = defineProps({
  config: {
    type: Object,
    default: () => {}
  },
  allowReset: {
    type: Boolean,
    default: false
  },
  allowOnlyInitialIds: {
    type: Boolean,
    default: false
  },
  showFilterOnNoResult: {
    type: Boolean,
    default: false
  },
  showMultiSelects: {
    type: Boolean,
    default: false
  }
})

const data = ref({})
const hasResults = ref(false)
const FilterForm = ref(null)
const fullJsonItems = ref([])
const jsonItems = ref([])
const filtersWithKeys = ref([])
const filteredItems = ref([])
const filters = ref([])
const selectOptions = ref([])
const categoryKeys = ref([])
const preFilterCategories = ref([])
const preFilterValues = ref([])
const preFiltersProcessed = ref(false)
const configFilters = ref([])
const initialSelectOption = ref([])
const loadData = ref(false)
const fetchItemsCounter = ref(0)
const allowedIds = ref([])
const searchInput = ref('')
const resetMultiSelectsRef = ref(false)
const activeSelect = ref(null)
const flyOutFilterActive = ref(false)
const catUid = ref('')
const countriesJsonData = ref(null)
const getCountryJsonData = ref(null)
const cookieData = ref(null)
const increasingFilterCount = ref(false)
const decreasingFilterCount = ref(false)
const COOKIE_NAME = 'cookieCountry'

const filterResultsNoCountry = ref(null)

// const isFirstRun = ref(true)

const searchContainer = window.searchContainer
const disableExternalDocuments = window.disableExternalDocuments

const showFilter = () => {
  return (
    configFilters.value &&
    ((props.showMultiSelects && hasResults.value) ||
      props.showFilterOnNoResult ||
      (configFilters.value.length > 0 && selectOptions.value.length > 0))
  )
}

const configFiltersWithoutUnsolicitedApplicationAndTalentPool = computed(() => {
  return configFilters.value.filter((configFilter) => {
    return (
      configFilter.key !== CategoryKeys.UNSOLICITED_APPLICATION &&
      configFilter.key !== CategoryKeys?.TALENTPOOL
    )
  })
})

const showHelpText = computed(() => {
  return (
    props.config.texts.helpText &&
    !props.config.loadInitialData &&
    !hasResults.value &&
    !props.config.withImageMap
  )
})

const showResultText = computed(() => {
  return props.config.texts.resultText && !props.config.withImageMap && !loadData.value
})

const showReset = computed(() => {
  return (
    props.allowReset &&
    filters.value.length &&
    (searchInput.value || filteredItems.value.length || filters.value.length > 0)
  )
})

const handleSearchInput = (searchInputData) => {
  searchInput.value = searchInputData
}

const getContentFromUrl = (url) => {
  setLoadingData(true)
  hasResults.value = false
  axios
    .get(url)
    .then((response) => {
      data.value = response.data
      fullJsonItems.value = data.value.items
      // Filter for FAQ
      if (props.allowOnlyInitialIds) {
        allowedIds.value = data.value.items.map((item) => item.id.toString())
        fullJsonItems.value = allowedIds.value
      }

      if (props.config.withExternalData) {
        // External data has to be preprocessed to match the structure of the other lists
        preprocessData(data.value.downloads)
      } else {
        if (props.config.withImageMap) {
          fullJsonItems.value = data.value.items
          initImageMapFilter(data.value.items)
        } else {
          fullJsonItems.value = data.value.items
          initFilter(data.value.items)
        }
      }
    })
    .catch((error) => {
      console.error(error)
      setLoadingData(false)
    })
}

const preprocessData = (downloads) => {
  const category = Object.keys(downloads)[0]
  const remoteData = downloads[category]

  let items = []
  // Get the required category-keys
  const categories = {}
  categoryKeys.value = [] // Performance-optimization - store keys
  configFilters.value.forEach((x) => {
    categories[x.key] = x.label
    categoryKeys.value.push(x.key)
  })

  // Get keyword-translations
  axios
    .get(props.config.mappingUrl)
    .then((response) => {
      for (const filter of configFilters.value) {
        delete Object.assign(remoteData.filterCategories, {
          [filter.key]: remoteData.filterCategories[filter.key]
        })[filter.key]
      }
      const mappings = response.data

      // Adjust item-structure
      items = remoteData.files

      fetchItemsCounter.value++

      // Change urls to files
      for (const [index, item] of items.entries()) {
        // add indentifier if not available
        if (!item.identifier) {
          item.identifier = `item-${fetchItemsCounter.value}-${index}`
        }

        // Add filterCategories to the items
        item.filterCategories = {}
        item.text = {}

        mapItemValues(item, mappings, category)

        for (const categoryKey of categoryKeys.value) {
          const categoryName = categories[categoryKey]
          if (
            category === DownloadCategory.APPROVALS &&
            (categoryKey === CategoryKeys.REGION ||
              categoryKey === CategoryKeys.APPLICATION ||
              categoryKey === CategoryKeys.PRODUCT_CODELIST ||
              categoryKey === CategoryKeys.SCHEME ||
              categoryKey === CategoryKeys.SCOPE ||
              categoryKey === CategoryKeys.DETAIL)
          ) {
            const itemList = []
            if (categoryKey === CategoryKeys.PRODUCT_CODELIST) {
              item.productcodeList.forEach((listItem) =>
                listItem['title'].forEach((dataItem) => {
                  if (!itemList.includes(dataItem)) {
                    itemList.push(dataItem)
                  }
                })
              )
              item[categoryKey] = itemList
            } else {
              item.productcodeList.forEach((listItem) =>
                listItem[categoryKey].forEach((dataItem) => {
                  if (!itemList.includes(dataItem)) {
                    itemList.push(dataItem)
                  }
                })
              )
              item[categoryKey] = itemList
            }
          }
          item.filterCategories[categoryKey] = item[categoryKey]
          switch (categoryKey) {
            case CategoryKeys.APPROVAL_AUTHORITY:
              item.text[categoryName] = item[categoryKey]

              if (category === DownloadCategory.CERTIFICATES) {
                // Add certificate specific wtParams
                item.wtParams = {
                  ...item.wtParams,
                  description: item[categoryKey]
                }
                // Add certificate specific validto text (is not a filterCategory)
                item.text[props.config.texts.validto] = convertISODateTimeToLocaleDate(
                  item.validto,
                  window.currentLanguage,
                  props.config.texts.emptyExpDateText
                )
              }
              break
            case CategoryKeys.PRODUCT_CODE:
              // Make productcodes expandable
              item.subHeading = {}
              item.subHeading[categoryName] = item[categoryKey]
              break
            case CategoryKeys.PRODUCT_CODELIST:
              if (category === DownloadCategory.APPROVALS) {
                const itemList = []
                item.productcodeList.forEach((listItem) =>
                  listItem.title.forEach((dataItem) => {
                    if (!itemList.includes(dataItem)) {
                      itemList.push(dataItem)
                    }
                  })
                )
                item.subHeading = {}

                let modelLabel = props.config.texts.type
                if (itemList.length > 1) {
                  modelLabel = props.config.texts.types
                }

                item.subHeading[modelLabel] = itemList
              }
              break
            case CategoryKeys.REGION:
              if (category === DownloadCategory.APPROVALS) {
                const itemList = []
                item.productcodeList.forEach((listItem) =>
                  listItem.region.forEach((dataItem) => {
                    if (!itemList.includes(dataItem)) {
                      itemList.push(dataItem)
                    }
                  })
                )
                item.text[categoryName] = itemList
              }
              break
            case CategoryKeys.APPLICATION:
              if (category === DownloadCategory.APPROVALS) {
                const itemList = []
                item.productcodeList.forEach((listItem) =>
                  listItem.application.forEach((dataItem) => {
                    if (!itemList.includes(dataItem)) {
                      itemList.push(dataItem)
                    }
                  })
                )
                item.text[categoryName] = itemList
              }
              break
            case CategoryKeys.SCOPE:
              if (category === DownloadCategory.APPROVALS) {
                const itemList = []
                item.productcodeList.forEach((listItem) =>
                  listItem.scope.forEach((dataItem) => {
                    if (!itemList.includes(dataItem)) {
                      itemList.push(dataItem)
                    }
                  })
                )
                item.text[categoryName] = itemList
              }
              if (category === DownloadCategory.CERTIFICATES) {
                item.text[categoryName] = item[categoryKey]
              }
              break
            case CategoryKeys.SCHEME:
              if (category === DownloadCategory.APPROVALS) {
                const itemList = []
                item.productcodeList.forEach((listItem) =>
                  listItem.scheme.forEach((dataItem) => {
                    if (!itemList.includes(dataItem)) {
                      itemList.push(dataItem)
                    }
                  })
                )
                item.text[categoryName] = itemList
              }
              break
            case CategoryKeys.DETAIL:
              if (category === DownloadCategory.APPROVALS) {
                const itemList = []
                item.productcodeList.forEach((listItem) =>
                  listItem.detail.forEach((dataItem) => {
                    if (!itemList.includes(dataItem)) {
                      itemList.push(dataItem)
                    }
                  })
                )
                item.text[categoryName] = itemList
              }
              break
            default:
              item.text[categoryName] = item[categoryKey]
              break
          }
        }
      }

      // callback.apply(this, [items])
      fullJsonItems.value = items
      initFilter(items)
    })
    .catch((error) => console.error(error))
}

const mapItemValues = (item, mappings, category) => {
  const urlPrefix = window.externalDocumentPrefixes[category] || ''
  item.files = [...new Set(item.urls)]
  const langItem = item.files.find(
    (langItem) => langItem.language === window.currentLanguage.toLowerCase()
  )
  const title = langItem && langItem.title ? langItem.title : item.title
  item.title = `${title}`
  item.urls.forEach((x) => {
    x.flag = mappings.languages[x.language] || ''
    x.url = `${urlPrefix}${x.url}`
  })
  if (category !== DownloadCategory.DECLARATIONS) {
    if (category !== DownloadCategory.CERTIFICATES) {
      mapKeyWordsAndProductTypes(item, mappings)
    } else {
      mapRegionAreas(item, mappings)
    }
  }

  mapEconomicAreas(item, mappings)
}

const mapEconomicAreas = (item, mappings) => {
  item.economicArea = item.economicArea.map(
    (x) =>
      getValueByParameterCaseInsensitive(mappings.economicAreas, x) ||
      getValueByParameterCaseInsensitive(mappings.countries, x) ||
      x
  )
}

const mapRegionAreas = (item, mappings) => {
  item.regionList = item.regionList.map(
    (x) => getValueByParameterCaseInsensitive(mappings.countries, x) || x
  )
}

const mapKeyWordsAndProductTypes = (item, mappings) => {
  item.keywords = item.keywords.map((x) => mappings.keywords[x.id] || x.keyword)

  // Add productTypes if they are available
  item.productTypes = (!!item.product && [item.product]) || item.productcode || []
}

const canShowSearch = () => {
  // just faq and jobs search are provided so far
  return (
    props.config.hasSearch &&
    (props.config.listCategory === ListCategory.FAQ ||
      props.config.listCategory === ListCategory.JOBS)
  )
}

const getSearchResults = (searchArray) => {
  reset(true)

  if (props.config.listCategory === ListCategory.FAQ) {
    catUid.value = 'faqs'
  } else if (props.config.listCategory === ListCategory.JOBS) {
    catUid.value = 'joboffers'
  }

  if (!searchArray || !searchArray.length || (searchArray.length === 1 && searchArray[0] === '')) {
    getContentFromUrl(props.config.url)
  } else {
    setLoadingData(true)
    getSearchResultFromUrl(props.config.searchService, {
      from: 0,
      size: 0,
      container: searchContainer,
      disableExternalDocuments: disableExternalDocuments,
      categoryuid: catUid.value,
      word: searchArray
    })
      .then((responseData) => {
        if (props.config.listCategory === ListCategory.FAQ) {
          mapFaqSearchResult(responseData)
        } else if (props.config.listCategory === ListCategory.JOBS) {
          mapJobsSearchResult(responseData)
        }
      })
      .catch((error) => {
        console.error(error)
        setLoadingData(false)
      })
  }
}

const mapFaqSearchResult = (responseData) => {
  const results = ((responseData.hits && responseData.hits.hits) || [])
    .filter(
      (item) =>
        !props.allowOnlyInitialIds ||
        (item._source.faqId && allowedIds.value.includes(item._source.faqId.toString()))
    )
    .map((item) => {
      return {
        ...item._source,
        answer: item._source.description,
        id: item._source.faqId,
        question: item._source.title
      }
    })
  handleFaqSearchResult(results)
}

const handleFaqSearchResult = (data) => {
  let resultList = []
  if (data) {
    let filterCategories = {}
    for (const result of data) {
      let branches = []
      let productlines = []
      let measuredRanges = []
      let types = []
      let categoryArray = []

      if (result.branch && result.branch.length) {
        for (const item of result.branch) {
          branches.push(item)
        }
      }

      if (result.productTypes && result.productTypes.length) {
        for (const item of result.productTypes) {
          types.push(item)
        }
      }

      if (result.productline && result.productline.length) {
        for (const item of result.productline) {
          productlines.push(item)
        }
      }

      if (result.measured_range && result.measured_range.length) {
        for (const item of result.measured_range) {
          measuredRanges.push(item)
        }
      }

      categoryArray.push(measuredRanges, productlines, branches, types)

      for (const index in categoryKeys.value) {
        if (categoryArray[index]) {
          filterCategories = {
            ...filterCategories,
            [categoryKeys.value[index]]: categoryArray[index]
          }
        }
      }

      resultList.push({
        question: result.question,
        answer: result.answer,
        id: result.id,
        filterCategories: filterCategories
      })
    }
  }

  filteredItems.value = resultList || []
  preFiltersProcessed.value = false
  hasResults.value = !!resultList.length
  initFilter(resultList)
}

const mapJobsSearchResult = (responseData) => {
  const results = filteredItems.value.filter((item) =>
    ((responseData.hits && responseData.hits.hits) || []).some(
      (hit) => hit._source.jobofferid === item.jobofferid
    )
  )
  filteredItems.value = results || []
  preFiltersProcessed.value = false
  hasResults.value = !!filteredItems.value
  initFilter(filteredItems.value)
}

const compareValues = (key, order = 'asc') => {
  return function innerSort(a, b) {
    if (!a.sorting || !b.sorting) {
      return 0
    }
    Object.keys(a.sorting).includes(key)
    if (!Object.keys(a.sorting).includes(key) || !Object.keys(b.sorting).includes(key)) {
      return 0
    }
    const varA = typeof a.sorting[key] === 'string' ? a.sorting[key].toUpperCase() : a.sorting[key]
    const varB = typeof b.sorting[key] === 'string' ? b.sorting[key].toUpperCase() : b.sorting[key]
    let comparison = 0
    if (varA > varB) {
      comparison = 1
    } else if (varA < varB) {
      comparison = -1
    }
    return order === 'desc' ? comparison * -1 : comparison
  }
}

const initImageMapFilter = (data) => {
  jsonItems.value = data
  const detail = { id: 'all' }
  updateFromLink(detail)
}

const initFilter = (initialData) => {
  const initPreFilterValues = [...preFilterValues.value]
  const initPreFilterCategories = [...preFilterCategories.value]
  setLoadingData(true)
  if (props.config.filterExcludes && props.config.filterExcludes['empty_measured_ranges']) {
    initialData &&
      initialData.filter((item) => item.filterCategories['measured_range'].length !== 0)
  }

  //Remove TALENTPOOL data
  initialData &&
    initialData.filter((item) => item.filterCategories[CategoryKeys.TALENTPOOL] !== 'true')

  jsonItems.value = initialData

  categoryKeys.value =
    initialData && initialData.length ? Object.keys(initialData[0].filterCategories) : []

  configFilters.value = JSON.parse(JSON.stringify(filtersWithKeys.value))

  const selectOptionsBuffer = []
  let presets = initPreselection()

  for (const filter of configFilters.value) {
    if (filter.include && filter.include.length !== 0 && !preFiltersProcessed.value) {
      initPreFilterValues.push(filter.include[0])
      filter.initialSelectOption = filter.include[0]
    } else if (!preFiltersProcessed.value) {
      if (presets['selectedCategory'] && presets['selectedCountry']) {
        filter.key === configFilters.value[0].key &&
          (initPreFilterValues.push(presets['selectedCategory']),
          initPreFilterCategories.push(filter.key),
          (filter.initialSelectOption = presets['selectedCategory']))
        filter.key === configFilters.value[1].key &&
          (initPreFilterValues.push(presets['selectedCountry']),
          initPreFilterCategories.push(filter.key),
          (filter.initialSelectOption = presets['selectedCountry']))
      } else if (presets[filter.key]) {
        if (presets[filter.key].includes('^')) {
          const presetArray = presets[filter.key].split('^')
          initPreFilterValues.push(presetArray)
        } else {
          initPreFilterValues.push(presets[filter.key])
        }
        initPreFilterCategories.push(filter.key)
      }
    }

    preFilterValues.value = initPreFilterValues
    preFilterCategories.value = initPreFilterCategories
  }

  if (initialData.length) {
    if (configFilters.value.some((filter) => filter.isMultiSelect)) {
      for (let i = 0; i < fullJsonItems.value.length; i++) {
        const keys = configFilters.value.filter((e) => e.key !== '').map((e) => e.key)
        // country always allow multiselect option
        const values = keys.map((e) =>
          e === 'country'
            ? fullJsonItems.value[i].filterCategories['country']
            : initialData[i]?.filterCategories[e]
        )

        for (let j = 0; j < keys.length; j++) {
          if (values[j]) {
            if (!selectOptionsBuffer[j]) {
              selectOptionsBuffer[j] = []
            }
            if (!Array.isArray(values[j])) {
              if (!selectOptionsBuffer[j].includes(values[j])) {
                selectOptionsBuffer[j].push(values[j])
              }
            } else {
              for (const value of values[j]) {
                if (!selectOptionsBuffer[j].includes(value)) {
                  selectOptionsBuffer[j].push(value)
                }
              }
            }
          }
        }
      }
    } else {
      for (let i = 0; i < initialData.length; i++) {
        const keys = configFilters.value.filter((e) => e.key !== '').map((e) => e.key)
        const values = keys.map((e) => initialData[i].filterCategories[e])

        for (let j = 0; j < keys.length; j++) {
          if (values[j]) {
            if (!selectOptionsBuffer[j]) {
              selectOptionsBuffer[j] = []
            }
            if (!Array.isArray(values[j])) {
              if (!selectOptionsBuffer[j].includes(values[j])) {
                selectOptionsBuffer[j].push(values[j])
              }
            } else {
              for (const value of values[j]) {
                if (!selectOptionsBuffer[j].includes(value)) {
                  selectOptionsBuffer[j].push(value)
                }
              }
            }
          }
        }
      }
    }
  }

  selectOptions.value = sortFilterOptions(selectOptionsBuffer)

  //Remove blacklist elements
  if (selectOptions.value && props.config.filterExcludes) {
    if (selectOptions.value !== null && selectOptions.value.length > 0) {
      //Remove meassured_ranges
      for (const elm of props.config.filterExcludes['measured_range']) {
        selectOptions.value[0] = selectOptions.value[0].filter((item) => !item.includes(elm))
      }
      if (selectOptions.value !== null && selectOptions.value.length > 1) {
        //Remove productlines
        for (const elm of props.config.filterExcludes['productline']) {
          selectOptions.value[1] = selectOptions.value[1].filter((item) => !item.includes(elm))
        }
      }
    }
  }

  if (preFilterValues.value.length !== 0 && !preFiltersProcessed.value) {
    return preFilterResults()
  } else {
    preFiltersProcessed.value = true
  }

  const results = props.config.loadInitialData
    ? initialData?.sort(
        compareValues(props.config.sorting?.field, props.config.sorting?.ascending ? 'asc' : 'desc')
      )
    : initialData

  filteredItems.value = results || []

  setLoadingData(false)
  hasResults.value = !!filteredItems.value
}

const sortFilterOptions = (selectOptionsBufferRaw) => {
  selectOptionsBufferRaw.forEach((selectOption, index) => {
    const currentFilter = configFilters.value[index]
    if (currentFilter && currentFilter.sort) {
      if (currentFilter.dataType === FormFilterDataTypeEnum.MONTH) {
        selectOption.sort((a, b) => {
          const nameA = currentFilter.monthLabels.indexOf(a)
          const nameB = currentFilter.monthLabels.indexOf(b)
          return nameA < nameB ? -1 : nameA > nameB ? 1 : 0
        })
      } else {
        sortInGivenArray(selectOption, currentFilter?.sort)
      }
      return selectOption.unshift(`${props.config.resetSelectOption}`)
    }
  })

  return selectOptionsBufferRaw
}

const sortFilters = (chosenItems) => {
  let sortFilterItems

  if (configFilters.value.some((filter) => filter.isMultiSelect)) {
    sortFilterItems = fullJsonItems.value
  } else {
    sortFilterItems = filters.value?.length
      ? filteredItems.value
      : jsonItems.value || filteredItems.value

    if (chosenItems.length <= 1) {
      sortFilterItems = fullJsonItems.value && fullJsonItems.value
    }
  }

  let newItems

  [...chosenItems].forEach((filter) => {
    newItems = sortFilterItems?.filter((item) => {
      const itemCategory =
        item.filterCategories[Object.keys(filter)[0]] || item.filterCategories[filter[0]]
      const filterValue = Object.values(filter)[0] || filter[1]
      if (Array.isArray(filterValue)) {
        if (itemCategory) {
          return typeof itemCategory === 'string'
            ? filterValue.some((value) => value === itemCategory)
            : filterValue.some((value) => itemCategory.includes(value))
        }
      } else {
        if (itemCategory) {
          return typeof itemCategory === 'string'
            ? itemCategory === filterValue
            : itemCategory.includes(filterValue)
        }
      }
    })
    return false
  })

  if (newItems?.length) {
    initFilter(newItems)
  } else {
    initFilter(sortFilterItems)
  }
}

const preFilterResults = () => {
  let preFilterFilters = []
  for (let i = 0; i < preFilterCategories.value.length; i++) {
    const preFilterValue = preFilterValues.value[i].includes(',')
      ? [preFilterValues.value[i].split(',')]
      : preFilterValues.value[i]

    if (
      !Array.isArray(preFilterValue) &&
      selectOptions.value.findIndex((optionGroup) => optionGroup.includes(preFilterValue)) > -1
    ) {
      preFilterFilters.push({
        [preFilterCategories.value[i]]: preFilterValue
      })
      activeSelect.value = preFilterCategories.value[i]
    } else if (
      Array.isArray(preFilterValue) &&
      selectOptions.value.findIndex((optionGroup) =>
        preFilterValue.some((item) => optionGroup.includes(item))
      ) > -1
    ) {
      preFilterFilters.push({
        [preFilterCategories.value[i]]: preFilterValue
      })
      activeSelect.value = preFilterCategories.value[i]
    }
    filters.value = preFilterFilters
  }
  preFiltersProcessed.value = true
  if (props.config.hasMultiSelects) {
    setTimeout(() => {
      filterResults()
    }, 0)
  } else {
    filterResults()
  }
}

const updateFromTeaser = (title) => {
  configFilters.value[0].initialSelectOption = title
  updateFilters({
    label: Object.keys(data.value[0].filterCategories)[0],
    initialSelectOption: title
  })
}

const updateFromLink = (detail) => {
  setLoadingData(true)
  filteredItems.value = []
  let products = []
  const items = []
  for (const filter of props.config.filterList) {
    if (filter.id === detail.id) {
      products = filter.products
    }
  }

  for (const item of jsonItems.value) {
    if (products.includes(item.id)) {
      items.push(item)
    }
  }
  const results = items.sort((a, b) => products.indexOf(a.id) - products.indexOf(b.id))
  setLoadingData(false)

  filteredItems.value = results || []
  hasResults.value = !!filteredItems.value
  emit('has-results', hasResults.value)
}

const updateFilters = (filter, $options) => {
  if (filter === undefined || !$options) {
    return null
  }

  activeSelect.value = filter.key
  const query = location.search.substring(1)
  const params = new URLSearchParams(query)
  const filtersUpdateFilters = [...filters.value]

  filters.value &&
    filters.value.forEach((item) => {
      if (item[activeSelect.value]?.toString()) {
        const indexToDelete = filtersUpdateFilters.findIndex((item) => {
          return Object.keys(item)[0] === activeSelect.value
        })

        filtersUpdateFilters.splice(indexToDelete, 1)
      }

      if (item[activeSelect.value]?.toString()) {
        preFilterCategories.value.filter((filterCategories) => {
          return filterCategories !== filter.key
        })
      }

      if (item[activeSelect.value]?.toString()) {
        params.has(activeSelect.value) && params.delete(activeSelect.value)
      }
    })

  if (activeSelect.value === CategoryKeys.UNSOLICITED_APPLICATION) {
    filtersUpdateFilters.push({ [activeSelect.value]: true })
    if (params.has(activeSelect.value)) {
      params.set(activeSelect.value, true)
    } else {
      params.append(activeSelect.value, true)
    }
  } else {
    if (
      filter.initialSelectOption !== props.config.resetSelectOption &&
      $options &&
      $options[0] !== filter.placeholder
    ) {
      if ($options && $options.length) {
        if ($options[0] !== props.config.resetSelectOption) {
          filtersUpdateFilters.push({
            [activeSelect.value]: $options.length === 0 ? [$options] : $options
          })
        } else {
          [...filtersUpdateFilters].splice(
            filtersUpdateFilters.findIndex((filter) => {
              return Object.keys(filter)[0] === filter.key
            }),
            1
          )
        }
      }

      if (($options[0] || $options) !== filter.placeholder) {
        if (params.has(activeSelect.value)) {
          params.set(activeSelect.value, $options)
        } else {
          params.append(activeSelect.value, $options)
        }

        if (($options[0] || $options) === props.config.resetSelectOption) {
          params.delete(activeSelect.value)
        }
      }
    }
  }

  filters.value = filtersUpdateFilters

  const urlWithNewParams = `${location.pathname}?${params.toString()}`
  history.pushState(null, '', urlWithNewParams)
  jsonItems.value = fullJsonItems.value
  filterResults()
}

const filterResults = () => {
  setLoadingData(true)
  let filteredResultsItems = data.value?.items

  if (configFilters.value.some((filter) => filter.isMultiSelect)) {
    filteredResultsItems = fullJsonItems.value

    if (filters.value.length > 1) {
      filteredResultsItems = filteredItems.value.length ? filteredItems.value : fullJsonItems.value
    }

    if (filters.value.some((filter) => filter[CategoryKeys.UNSOLICITED_APPLICATION])) {
      filteredResultsItems = filteredItems.value
      if (
        (filters.value.length <= 2 && decreasingFilterCount.value) ||
        filters.value.length === 1
      ) {
        filteredResultsItems = fullJsonItems.value
      }
    }
  } else {
    filteredResultsItems =
      filters.value.length <= 1 ? fullJsonItems.value : filteredItems.value || jsonItems.value
  }

  let finalFilteredItems

  const chosenItems = []

  const filterResultsFunction = (arrayToFilter) => {
    let reducerReturn
    for (const filterRaw of filters.value) {
      let filterKey
      const filter = JSON.parse(JSON.stringify(filterRaw))
      if (configFilters.value.find((configFilter) => configFilter.isMultiSelect)) {
        filterKey = Object.keys(filter)[0]
      } else {
        filterKey = Object.keys(filter)
      }
      let filterValue = filter[filterKey]

      let formattedFilterValue
      if (Array.isArray(filterValue)) {
        formattedFilterValue = filterValue
      } else if (typeof filterValue === 'object') {
        formattedFilterValue = [...filterValue]
      } else {
        formattedFilterValue = [filterValue]
      }

      reducerReturn = arrayToFilter.filter((item) => {
        const itemCategory = item.filterCategories[filterKey]

        if (filterKey === CategoryKeys.UNSOLICITED_APPLICATION) {
          if (itemCategory === 'true') {
            return (formattedFilterValue = 'true')
          } else {
            return
          }
        } else {
          if (Array.isArray(formattedFilterValue)) {
            if (itemCategory) {
              if (typeof itemCategory === 'string') {
                return filterValue.includes(itemCategory)
              } else {
                return formattedFilterValue.some((value) => itemCategory.includes(value))
              }
            }
          } else {
            if (itemCategory) {
              if (typeof itemCategory === 'string') {
                return (formattedFilterValue = [itemCategory])
              } else {
                return itemCategory.includes(formattedFilterValue)
              }
            }
          }
        }
      })

      filterValue = formattedFilterValue

      if (filterKey !== 'country') {
        filterResultsNoCountry.value = reducerReturn
      }

      const chosenItemItem = [...chosenItems]?.find((item) => item[filterKey])

      if (chosenItemItem) {
        chosenItemItem[filterKey] = filterValue
      } else {
        chosenItems.push({ [filterKey]: filterValue })
      }
    }
    return reducerReturn
  }

  if (filters.value?.length <= 1) {
    finalFilteredItems = filters.value.reduce(filterResultsFunction, fullJsonItems.value)
  } else {
    if (!configFilters.value.find((filter) => filter.isMultiSelect)) {
      finalFilteredItems = filters.value.reduce(filterResultsFunction, filteredResultsItems)
    } else {
      finalFilteredItems = filters.value.reduce(filterResultsFunction, filteredResultsItems)

      if (filters.value.find((filter) => filter['country'])['country'].length > 1) {
        // regenerate filterResultsNoCountry.value to add results instead of filter out results
        if (filters.value.length <= 2) {
          finalFilteredItems = filters.value.reduce(filterResultsFunction, fullJsonItems.value)
        } else {
          finalFilteredItems = filters.value.reduce(filterResultsFunction, filteredResultsItems)
        }
        finalFilteredItems = filters.value.reduce(
          filterResultsFunction,
          filterResultsNoCountry.value
        )
      }
    }
  }

  if (!props.config.withTeasers) {
    sortFilters(chosenItems)
  }

  const results = props.config.sorting
    ? finalFilteredItems?.sort(
        compareValues(props.config.sorting.field, props.config.sorting.ascending ? 'asc' : 'desc')
      )
    : finalFilteredItems

  if (filters.value.length > 0) {
    filteredItems.value = results || []
  } else {
    filteredItems.value = fullJsonItems.value
  }

  hasResults.value = !!filteredItems.value
  emit('has-results', hasResults.value)
  setLoadingData(false)
}

const initPreselection = () => {
  const query = location.search.substring(1)
  const result = {}
  const regexPlus = /\+/g
  if (query === '') {
    return result
  }

  query.split('&').forEach(function (part) {
    const item = part.split('=')
    item[1] = regexPlus.test(item[1]) ? item[1].replace(regexPlus, '%20') : item[1]
    result[item[0]] = decodeURIComponent(item[1])
  })

  return result
}

const handleFilterButtonChange = () => {
  if (!flyOutFilterActive.value) {
    updateFilters(
      configFilters.value.find((filter) => {
        return filter.key === CategoryKeys.UNSOLICITED_APPLICATION
      }),
      'true'
    )

    flyOutFilterActive.value = true
    FilterForm.value?.scrollIntoView({ behavior: 'smooth' })
    resetMultiSelectsRef.value = false
  } else {
    const indexToRemove = filters.value.findIndex(
      (filter) => filter[CategoryKeys.UNSOLICITED_APPLICATION]
    )
    // remove the reset filter from the selected filters
    filters.value.splice(indexToRemove, 1)

    if (filters.value.length !== 0) {
      filters.value.forEach((filter) => {
        updateFilters(
          configFilters.value.find(
            (configFilter) => configFilter.key === Object.keys({ ...filter })[0]
          ),
          [...Object.values(filter)[0]]
        )
      })
    } else {
      reset()
    }

    const query = location.search.substring(1)
    const params = new URLSearchParams(query)
    params.has(CategoryKeys.UNSOLICITED_APPLICATION) &&
      params.delete(CategoryKeys.UNSOLICITED_APPLICATION)
    const urlWithNewParams = `${location.pathname}?${params.toString()}`
    history.pushState(null, '', urlWithNewParams)

    flyOutFilterActive.value = false
    FilterForm.value?.scrollIntoView({ behavior: 'smooth' })
  }
}

const searchReset = (inputField) => {
  if (props.allowReset && inputField.length === 0) {
    jsonItems.value = data.value.items
    fullJsonItems.value = data.value.items

    filterResults()
  }
}

const reset = (preventInputReset) => {
  preFilterCategories.value = []
  preFilterValues.value = []
  preFiltersProcessed.value = false
  const query = location.search.substring(1)
  const params = new URLSearchParams(query)

  filters.value = []
  filteredItems.value = fullJsonItems.value
  filterResultsNoCountry.value = null

  for (let index = 0; index < configFilters.value.length; index++) {
    configFilters.value[index].initialSelectOption =
      configFilters.value[index].placeholder || props.config.resetSelectOption
    const filterKey = configFilters.value[index].key
    params.has(filterKey) && params.delete(filterKey)
  }

  if (!preventInputReset) {
    FilterForm.value = null
    searchInput.value = ''
  }

  flyOutFilterActive.value = false

  jsonItems.value = props.config.inlineData ? props.config.inlineData.items : data.value.items
  const urlWithNewParams = `${location.pathname}?${params.toString()}`
  history.pushState(null, '', urlWithNewParams)
  filterResults()
}

const getInitialFilterValue = (configFilterRaw) => {
  const activeFilterKey = configFilterRaw.key
  const existingFilter = props.config.filters.some((filter) => filter.key === activeFilterKey)

  configFilterRaw.initialSelectOption = existingFilter
    ? configFilterRaw.initialSelectOption
    : [
        props.config.filters.find((filter) => filter.key === configFilterRaw.key)
          ?.initialSelectOption
      ]

  return configFilterRaw.initialSelectOption
}

const setLoadingData = (isLoading) => {
  loadData.value = isLoading
  emit('loadingChange', isLoading)
}

const getGeoIp = () => {
  axios
    .get(window.geoIPUrl || geoIPUrl)
    .then((response) => (getCountryJsonData.value = response.data[0]))
    .catch((error) => console.error(error))
}

const getCountriesJSON = () => {
  axios
    .get(props.config.countriesJsonUrl)
    .then(
      (response) => (
        (countriesJsonData.value = response.data.countries),
        cookieData.value ? preselectCountryByCookie() : preselectCountryByGeoIP()
      )
    )
    .catch((error) => console.error(error))
}

const preselectCountryByCookie = () => {
  if (props.config.filters && props.config.filters.length > 0) {
    for (const filter of props.config.filters) {
      if (filter.key === 'country') {
        filter.initialSelectOption = [countriesJsonData.value[cookieData.value].name]
      }
    }
  }
}

const preselectCountryByGeoIP = () => {
  if (
    getCountryJsonData.value &&
    getCountryJsonData.value.country_code &&
    props.config.filters &&
    props.config.filters.length > 0
  ) {
    for (const filter of props.config.filters) {
      if (filter.key === 'country') {
        filter.initialSelectOption = [
          countriesJsonData.value[getCountryJsonData.value.country_code].name
        ]
      }
    }
  }
}

const filterResultsFromUrl = (params) => {
  filters.value = []

  filtersWithKeys.value?.forEach((configFilter) => {
    if (params.has(configFilter.key)) {
      const valueFromUrl = params.get(configFilter.key)
      let processedValueFromUrl
      if (configFilters.value.some((filter) => filter.isMultiSelect)) {
        if (valueFromUrl.includes(',')) {
          processedValueFromUrl = valueFromUrl.split(',')
        }
      }
      if (configFilter.key !== CategoryKeys.UNSOLICITED_APPLICATION) {
        filters.value.push({
          [configFilter.key]: processedValueFromUrl ? processedValueFromUrl : [valueFromUrl]
        })
      }
      configFilter.initialSelectOption = processedValueFromUrl
        ? processedValueFromUrl
        : valueFromUrl
    }
    return (configFilters.value = filtersWithKeys.value)
  })

  if (params.has(CategoryKeys.UNSOLICITED_APPLICATION)) {
    flyOutFilterActive.value = true
    filters.value.push({ [CategoryKeys.UNSOLICITED_APPLICATION]: true })
  }

  return
}

watch(hasResults, async (newValue, oldValue) => {
  if (newValue !== oldValue && newValue) {
    showFilter()
  }
})

watch(selectOptions, async (newValue, oldValue) => {
  if (newValue !== oldValue && newValue > 0) {
    showFilter()
  }
})

watch(resetMultiSelectsRef, async (newValue, oldValue) => {
  if (newValue !== oldValue && newValue) {
    reset()
  }
})

watch(filteredItems, async (newValue) => {
  emit('filtered', newValue)
})

watch(filters, (newValue, oldValue) => {
  if (newValue.length !== oldValue.length) {
    if (newValue.length > oldValue.length) {
      increasingFilterCount.value = true
      decreasingFilterCount.value = false
    } else {
      decreasingFilterCount.value = true
      increasingFilterCount.value = false
    }
  }
})

onBeforeMount(() => {
  hasResults.value = false
  configFilters.value = JSON.parse(JSON.stringify(props.config.filters))
  const query = location.search.substring(1)
  const params = new URLSearchParams(query)

  if (props.config.preselectUserCountry && props.config.countriesJsonUrl) {
    cookieData.value = Storage.getCookie(COOKIE_NAME)
    if (!cookieData.value) {
      getGeoIp()
    }
    getCountriesJSON()
  }

  if (props.config.filters && props.config.filters.length) {
    filtersWithKeys.value =
      props.config.filters.filter((filterWithKey) => filterWithKey.key !== '') || []
    initialSelectOption.value = filtersWithKeys.value[0].initialSelectOption
  }

  if (props.config.url) {
    getContentFromUrl(props.config.url)
  } else if (props.config.inlineData) {
    hasResults.value = false
    data.value = props.config.inlineData.items
    fullJsonItems.value = data.value
    initFilter(props.config.inlineData.items)
  } else {
    fullJsonItems.value = data.value
  }

  if (params) {
    filterResultsFromUrl(params)
  }
})

onMounted(() => {
  const query = location.search.substring(1)
  const params = new URLSearchParams(query)

  window.addEventListener('updateFilter', (event) => {
    updateFromLink(event.detail)
  })

  if (params) {
    filterResultsFromUrl(params)
  }
})

onBeforeUnmount(() => {
  reset()
  if (configFilters.value[0]) {
    configFilters.value[0].initialSelectOption = initialSelectOption.value
  }
})

onUnmounted(() => {
  window.removeEventListener('updateFilter', (event) => {
    updateFromLink(event.detail)
  })
})
</script>

<style module lang="scss">
@import './FormFilter.scss';
</style>
